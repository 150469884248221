
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { Box, Stack, Tab, TextField, useMediaQuery, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import regImage from "../../../../images/regImage.png"
import regImageMobile from "../../../../images/regImageMobile.png"
import { useContext, useState } from 'react';
import { AppContext, AuthContext, UserContext } from '../../../../store';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import SuccessIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { APIError } from '../../../../store/app';
import { MuiTelInput } from 'mui-tel-input';
import { StatefulPinInput } from 'react-input-pin-code';


export interface SimpleDialogProps {
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
}

export const EnterCodeModal = (props: SimpleDialogProps) => {
    const { onClose, selectedValue, open } = props;
    const handleClose = () => {
        onClose(selectedValue);
    };
    const isSmallMobile = useMediaQuery('(max-width: 500px)', { noSsr: true });
    const user = useContext(UserContext);
    const auth = useContext(AuthContext);
    const app = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    return (
        <Dialog onClose={handleClose} open={open} sx={{ '& .MuiPaper-root': { borderRadius: "16px" } }}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: isSmallMobile ? "column" : "row",
                        p: 2,
                        gap: 2
                    }}
                >
                    {isSuccess ? (
                        <Typography component="p" sx={{ textAlign: 'center', fontSize: '1.7rem', lineHeight: '1.9rem' }}>
                            <SuccessIcon color="success" sx={{ fontSize: 54 }} /> <br />
                            <strong>Всё хорошо!</strong> Теперь вы можете войти с указанными вами телефоном и паролем <br /><br />
                            <Button size="large" color="primary" variant="contained" onClick={handleClose}>Хорошо</Button>
                        </Typography>
                    ) : (
                        <>
                            <Box
                                component='img'
                                src={isSmallMobile ? regImageMobile : regImage}
                            />
                            <Stack
                                component="form"
                                gap={1}
                                onSubmit={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setIsLoading(true);

                                    const fd = new FormData(e.currentTarget);
                                    const code = fd.getAll('code').join('');

                                    auth.confirmRegistrationRetail({ code })
                                        .then(() => {
                                            setIsSuccess(true);
                                        })
                                        .catch((e: APIError) => {
                                            console.error(e);
                                            app.alert(e.response?.data?.meta.message || 'Непредвиденная ошибка', { severity: 'error' });

                                        })
                                        .finally(() => setIsLoading(false));
                                }}
                            >
                                <Typography>Ввод кода подтверждения</Typography>
                                <StatefulPinInput name="code" length={4} initialValue="    " />
                                {/* <TextField required name="code" label="Код из СМС" variant="outlined" /> */}
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    children="Отправить"
                                    loading={isLoading}
                                />
                            </Stack>
                        </>
                    )}
                </Box>
            </Box>
        </Dialog>
    );

}

const RegistrationModal = (props: SimpleDialogProps) => {
    const { onClose, selectedValue, open } = props;
    const handleClose = () => {
        onClose(selectedValue);
        setTimeout(() => setIsSuccess(false), 500);
    };
    const isSmallMobile = useMediaQuery('(max-width: 500px)', { noSsr: true });
    const user = useContext(UserContext);
    const auth = useContext(AuthContext);
    const app = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [value, setValue] = useState('1');


    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const [phone, setPhone] = useState('')

    const phoneChange = (newPhone) => {
        setPhone(newPhone)
    }

    return (
        <Dialog onClose={handleClose} open={open} sx={{ '& .MuiPaper-root': { borderRadius: "16px" } }}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} variant="fullWidth">
                            <Tab label="Розничный клиент" value="1" />
                            <Tab label="Оптовый клиент" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: isSmallMobile ? "column" : "row",
                                p: 2,
                                gap: 2
                            }}
                        >
                            <Box
                                component='img'
                                src={isSmallMobile ? regImageMobile : regImage}
                            />
                            <Stack
                                component="form"
                                gap={1}
                                onSubmit={async e => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setIsLoading(true);
                                    auth.registerRetail(e.currentTarget)
                                        .then(() => {
                                            onClose('success');
                                        })
                                        .catch((e: APIError) => {
                                            console.error(e);
                                            app.alert(e.response?.data?.meta.message || 'Непредвиденная ошибка', { severity: 'error' });
                                        })
                                        .finally(() => setIsLoading(false))
                                        ;
                                }}
                            >
                                <Typography>Регистрация</Typography>
                                {/* <TextField required name="name" label="ФИО" variant="outlined" /> */}
                                <MuiTelInput name="phone" defaultCountry="RU" label="Телефон" placeholder='+7XXXXXXXXXX' value={phone} onChange={phoneChange} sx={{ "& .MuiIconButton-root": { display: "none" } }} required />
                                <TextField required type="password" name="password" label="Пароль" variant="outlined" />
                                {/* <TextField required type="number" name="contactPhone" label="Номер телефона" variant="outlined" /> */}
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    children="Зарегистрироваться"
                                    loading={isLoading}
                                />
                            </Stack>
                        </Box>
                    </TabPanel>
                    <TabPanel value="2">
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: isSmallMobile ? "column" : "row",
                                p: 2,
                                gap: 2
                            }}
                        >
                            {isSuccess ? (
                                <Typography component="p" sx={{ textAlign: 'center', fontSize: '1.7rem', lineHeight: '1.9rem' }}>
                                    <SuccessIcon color="success" sx={{ fontSize: 54 }} /> <br />
                                    <strong>Спасибо!</strong> Мы обязательно свяжемся с вами в ближайшее время <br /><br />
                                    <Button size="large" color="primary" variant="contained" onClick={handleClose}>Хорошо</Button>
                                </Typography>
                            ) : (
                                <>
                                    <Box
                                        component='img'
                                        src={isSmallMobile ? regImageMobile : regImage}
                                    />
                                    <Stack
                                        component="form"
                                        gap={1}
                                        onSubmit={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setIsLoading(true);
                                            user.sendRegistrationRequest(e.currentTarget)
                                                .then(() => {
                                                    setIsSuccess(true);
                                                })
                                                .finally(() => setIsLoading(false));
                                        }}
                                    >
                                        <Typography>Регистрация</Typography>
                                        <TextField required name="name" label="ФИО" variant="outlined" />
                                        <TextField required name="companyName" label="Название компании/ИП" variant="outlined" />
                                        <TextField required name="contactPhone" label="Телефон для связи" variant="outlined" />
                                        <TextField required name="inn" label="ИНН" variant="outlined" />
                                        <TextField required name="email" label="E-mail" variant="outlined" />
                                        <LoadingButton
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            children="Отправить заявку"
                                            loading={isLoading}
                                        />
                                    </Stack>
                                </>
                            )}
                        </Box>
                    </TabPanel>
                </TabContext>
            </Box>
        </Dialog>
    );
}

export default observer(RegistrationModal);
