import { Box, Button, Dialog, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography, useTheme } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC, useContext, useState } from "react";
import image from "../../../images/retail.jpg"
import { DateTimePicker } from "@mui/x-date-pickers";
import { GarageContext } from "../../../store";

type Props = {

};

const RecordDialog: FC<Props> = () => {
    const theme = useTheme();
    const garage = useContext(GarageContext);

    const items = garage.items;


    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = (value: string) => {
        setOpen(false);
    };
    const [car, setCar] = useState(undefined as number);
    const handleChange = (event: SelectChangeEvent) => {
        setCar(+event.target.value);
    };

    const [value, setValue] = useState<Date | null>(new Date());
    return (<>
        <Button
            variant="outlined"
            sx={{
                position: "absolute",
                bottom: 20,
                left: 20,
                right: 20,
                fontWeight: "bold",
                border: "1px solid"
            }}
            onClick={handleClickOpen}
        >
            Запись в сервис
        </Button>
        <Dialog onClose={handleClose} open={open} maxWidth={false} sx={{ '& .MuiPaper-root': { borderRadius: "1rem" } }}
        >

            <Box sx={{ display: "flex", gap: 2, padding: 2, flexDirection: { xs: "column", md: "row" }, alignItems: "center" }}>
                <Box component="img" src={image} sx={{ maxWidth: 400, maxHeight: 300, borderRadius: "1rem", width: "100%" }} />
                <Stack sx={{ gap: 2, width: { xs: "100%", md: 300 } }}>
                    <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>Запись авто в сервис</Typography>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Авто</InputLabel>
                        <Select
                            value={`${car}`}
                            label="Age"
                            onChange={handleChange}
                        >
                            {items?.list.map(v => (
                                <MenuItem value={v.id}>{v.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <DateTimePicker
                        openTo="day"
                        label="Выберите время записи"
                        ampm={false}
                        value={value}
                        onChange={(newValue) => {
                            setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <TextField rows={4} multiline label="Комментарий" variant="outlined" />
                    <Button
                        onClick={() => setOpen(false)}
                        sx={{
                            backgroundColor: theme.palette.info.contrastText,
                            color: "white",
                            "&: hover": { backgroundColor: "#6C5DD3" }
                        }}
                        children="Записаться"
                    />
                </Stack>

            </Box>
        </Dialog >
    </>);
};

export default observer(RecordDialog);