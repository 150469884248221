import { Box, Button, Dialog, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Stack, Step, StepContent, StepLabel, Stepper, TextField, Typography, useTheme } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC, useContext, useState } from "react";
import { DateTimePicker } from "@mui/x-date-pickers";
import AddIcon from '@mui/icons-material/Add';
import { GarageContext } from "../../../../store";

type Props = {

};

const steps = [
    {
        label: 'Марка авто',

    },
    {
        label: 'Модель',

    },
    {
        label: 'VIN/FRAME/Номер кузова',
    },
    {
        label: 'Номер авто',

    },
    {
        label: 'Год выпуска',

    },
    {
        label: 'Кузов',

    },
    {
        label: 'Двигатель',

    },
    {
        label: 'Привод',

    },
    {
        label: 'Коробка',

    },
    {
        label: 'Модификация',
    },
    {
        label: 'Цвет',
    },
];

const AddCarModal: FC<Props> = () => {
    const theme = useTheme();
    const garage = useContext(GarageContext);

    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = (value: string) => {
        setOpen(false);
    };
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    const carNumber = true;
    return (<>
        <Button
            startIcon={<AddIcon />}
            onClick={handleClickOpen}
        >
            Добавить авто
        </Button>
        <Dialog onClose={handleClose} open={open} maxWidth={false} sx={{ '& .MuiPaper-root': { borderRadius: "1rem" } }}
        >
            {carNumber ?
                <Box sx={{ display: "flex", gap: 2, padding: 2, flexDirection: { xs: "column", md: "row" }, alignItems: "center" }}>
                    <Stack 
                        component="form" 
                        sx={{ gap: 2, width: { xs: "100%", md: 300 } }}
                        onSubmit={e => {
                            e.preventDefault();
                            
                            garage.createVehicleItemByStateCode(e.currentTarget)
                                .then(() => {
                                    setOpen(false);
                                })
                            ;
                        }}
                    >
                        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>Добавить авто</Typography>
                        <TextField name="stateCode" label="Номер вашего ТС" />
                        <Button
                            type="submit"
                            sx={{
                                backgroundColor: theme.palette.info.contrastText,
                                color: "white",
                                "&: hover": { backgroundColor: "#6C5DD3" }
                            }}
                            children="Добавить"
                        />
                    </Stack>
                </Box> :
                <Box sx={{ p: 1, width: 500 }}>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((step, index) => (
                            <Step key={step.label}>
                                <StepLabel>
                                    <Typography>
                                        {step.label}
                                    </Typography>
                                </StepLabel>
                                <StepContent>
                                    <TextField />
                                    <Box sx={{ mb: 2 }}>
                                        <div>
                                            <Button
                                                variant="contained"
                                                onClick={handleNext}
                                                sx={{ mt: 1, mr: 1 }}
                                            >
                                                {index === steps.length - 1 ? 'Завершить!' : 'Продолжить'}
                                            </Button>
                                            <Button
                                                disabled={index === 0}
                                                onClick={handleBack}
                                                sx={{ mt: 1, mr: 1 }}
                                            >
                                                Назад
                                            </Button>
                                        </div>
                                    </Box>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                    {activeStep === steps.length && (
                        <Paper square sx={{ p: 3, m: 3 }}>
                            <Typography>Все шаги выполнены!</Typography>
                            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                Сбросить
                            </Button>
                        </Paper>
                    )}
                </Box>
            }

        </Dialog >
    </>);
};

export default observer(AddCarModal);