import {observer} from "mobx-react-lite";
import {CatalogCard} from "../Item";
import { Item } from "../../common/item";
import {useContext} from "react";
import {
    AppContext,
    CartContext,
    CatalogContext,
    FavoritesContext,
    NotificationsContext,
    UserContext,
    WaitListContext
} from "../../store";
import {Box, Chip, Grid, Skeleton, Stack, Typography} from "@mui/material";
import NotificationEmailSetup from "../NotificationEmailSetup";
import {range} from "lodash";
import SimilarGrid from "../Item/SimilarGrid";
import Breadcrumbs from "../Breadcrumbs";

type Props = {
    readonly item: Readonly<Item>;
};

const ItemCardModal = (p: Props) => {
    const catalog = useContext(CatalogContext);
    const app = useContext(AppContext);

    const cart = useContext(CartContext);
    const favorites = useContext(FavoritesContext);
    const waitList = useContext(WaitListContext);
    const user = useContext(UserContext);
    const notifications = useContext(NotificationsContext);
    const notify = notifications.notify;

    const props = catalog.getItem(p.item.id)?.props;
    const similarItems = catalog.getSimilar(p.item.id);

    const favorite = favorites.includes(user.user?.id, p.item.id);

    const breadcrumbs = catalog.getItemBreadcrumbs(p.item.aliasFull);

    return (
        <Box>
            <Typography variant="h5">
                {p.item.label}
            </Typography>
            <Breadcrumbs items={breadcrumbs} />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                    <CatalogCard
                        item={p.item}
                        inCartCount={cart.itemsCount(user.user?.id, p.item.id)}
                        favorite={favorite}
                        alwaysExpanded
                        onFavoritesClick={async () => {
                            const { orderPrice, ...cartItem } = p.item;
                            if(favorite) {
                                await favorites.remove(cartItem, user.user?.id);
                                app.alert(`Удалено из избранного`);
                            }
                            else {
                                await favorites.add(cartItem, user.user?.id);
                                app.alert(`Добавлено в избранное`);
                            }

                        }}
                        onAddToCartClick={async count => {
                            const { orderPrice, ...cartItem } = p.item;
                            await cart.addWithAmount(cartItem, count, user.user?.id);

                            if(cartItem.count <= 0 && user.user?.matrixCode != 1) {
                                await waitList.add(cartItem, user.user?.id);
                                if(!notify.email)
                                    app.showDialog(<NotificationEmailSetup />);
                                app.alert(`Добавлено в корзину и лист ожидания`);
                            }
                            else {
                                app.alert(`Добавлено в корзину`);
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                    <Stack spacing={2}>
                        <Typography variant="h6">
                            <strong>Характеристики</strong>
                        </Typography>

                        {props?.length ? props.map(prop => (
                            <Box
                                key={prop.name}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    flexWrap: 'wrap',
                                    gap: 1,
                                }}
                            >
                                <strong>{prop.name}:</strong>
                                {prop.values.map(v => (
                                    <Chip key={v.value} label={v.value} variant="outlined" />
                                ))}
                            </Box>
                        )) : undefined}
                        {props?.length === 0 && (
                            <Typography variant="body1">
                                <strong>На данный момент список характеристик пуст</strong>
                            </Typography>
                        )}
                        {props === undefined && (
                            range(7).map(i => (
                                <Box key={i} sx={{
                                    display: 'flex',
                                    gap: 1,
                                    justifyContent: 'flex-start'
                                }}>
                                    <Skeleton
                                        variant="rounded"
                                        width={(10 + Math.random() * 15) + '%'}
                                        height={25}
                                    />
                                    <Skeleton
                                        variant="rounded"
                                        width={(80 - Math.random() * 25) + '%'}
                                        height={25}
                                    />
                                </Box>
                            ))
                        )}
                    </Stack>
                </Grid>
            </Grid>
            <Typography variant="h6" sx={{ my: 2 }}>
                <strong>Аналоги</strong>
            </Typography>
            <SimilarGrid itemCode={p.item.id} items={similarItems} />
        </Box>
    );
};

export default observer(ItemCardModal);