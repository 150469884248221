import { FC, useContext } from "react";
import Date from "../../../../Date";
import { formatPrice as $ } from "../../../../../utils";
import GetOrderInvoiceLink from "../../../../GetOrderInvoiceLink";
import { styled, TableRow as MuiTableRow, Box } from "@mui/material";
import { Dayjs } from "dayjs";
import { useNavigate } from "react-router-dom";
import BonusBadge from "../../../../bonus/BonusInfo/BonusBadge";
import { observer } from "mobx-react-lite";
import { BonusContext, UserContext } from "../../../../../store";
import LineThrough from "../../../../LineThrough";
import { themed } from "../../../../../theme";
import TableCell from "../../TableCell";
import StatusTableCell from "./StatusTableCell";

type Props = {
    readonly id: string;
    readonly humanId: string;
    readonly date: Dayjs;
    readonly payUntil?: Dayjs;
    readonly price: number;
    readonly priceBeforeDiscount: number;
    readonly comment?: string;
    readonly status: number;
    readonly recipient?: {
        readonly id: number;
        readonly name: string;
    }
    readonly bonus?: {
        readonly added: number;
        readonly subtracted: number;
        readonly deactivated: number;
    };
};

const StyledTableRow = styled(MuiTableRow)(() => ({
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const TableRow: FC<Props> = (p) => {
    const navigate = useNavigate();

    const bonus = useContext(BonusContext);
    const user = useContext(UserContext);

    const bonusValue = p.bonus?.added || 0;

    const bonusSubstracted = -p.bonus?.subtracted || 0;


    return (
        <StyledTableRow hover
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate(`/order/${p.id}`)}
        >
            <TableCell>{p.humanId}</TableCell>
            <TableCell><Date date={p.date}/></TableCell>
            <TableCell>
                {(p.priceBeforeDiscount - p.price) >= 0.01 && (<>
                    <LineThrough sx={{ display: 'inline' }}>
                        {$(p.priceBeforeDiscount)}
                    </LineThrough><br />
                </>)}
                {$(p.price)}
            </TableCell>
            {!user.user.childrenCount ? undefined : (
                <TableCell>
                    {p.recipient?.name || '-'}
                </TableCell>
            )}
            {bonus.status?.enabled && (
                <TableCell>
                    <BonusBadge
                        mode="order"
                        faded={!!p.bonus?.deactivated}
                        value={bonusValue}
                    />
                    {bonusSubstracted < 0 && (
                        <Box pt={1}>
                            <BonusBadge
                                mode="order"
                                faded={!!p.bonus?.deactivated}
                                value={bonusSubstracted}
                            />
                        </Box>
                    )}
                </TableCell>
            )}
            <TableCell>{p.comment || ''}</TableCell>
            <StatusTableCell status={p.status} />
            <TableCell>
                <GetOrderInvoiceLink id={p.id} status={p.status} payUntil={p.payUntil} placeholder="-" />
            </TableCell>
        </StyledTableRow>
    );

}

export default themed('OrderTableBodyRow', observer(TableRow));